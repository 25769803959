/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "./theme/ckeditor.scss";

.waste {
  color: var(--ion-color-waste);
}

.recycle {
  color: var(--ion-color-recycle);
}

.organic {
  color: var(--ion-color-organic);
}

.paper {
  color: var(--ion-color-paper);
}

.special {
  color: var(--ion-color-special);
}

.food {
  color: var(--ion-color-food);
}

.clean_up {
  color: var(--ion-color-clean_up);
}

.hard_waste {
  color: var(--ion-color-hard_waste);
}

.greenwaste {
  color: var(--ion-color-greenwaste);
}

.colour-dot {
  background: currentColor;
  border: 1px solid black;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 7px !important;
}

.colour-dot {
  border: 1px solid black;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
}

figure.attachment {
  margin: 0px;
  margin-right: 10px;
  display: inline-block;
}

figcaption.caption {
  display: none;
}

figure.attachment.pdf > figcaption.caption {
  display: inline-block;
  .size {
    display: none;
  }
}

figcaption.caption {
  display: none;
}

.bin-icon {
  font-size: 2.5em;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.logo {
  max-width: 85px;
  max-height: 40px;
  padding: 0px;
  margin: 0px;
}

ion-col.menu-col {
  text-align: center;
  border-bottom: 1px solid var(--ion-color-light-shade);
  border-right: 1px solid var(--ion-color-light-shade);
}

ion-col.menu-col:last-child {
  border-right: 1px solid var(--ion-color-light-shade);
}

google-map {
  min-width: 200px;
  min-height: 200px;
}

.container-grid {
  height: 100%;
}

.container-row {
  height: 100%;
}

.container-col {
  min-height: 50%;
}

.widget-btn:hover {
  background-color: var(--ion-color-light-tint);
  color: white;
}

[data-trix-content-type="image/png"] {
  pointer-events: none;
}

.ck-content a {
  color: var(--ion-color-secondary);
  text-decoration: underline;
  cursor: pointer;
}

.secondary-bg {
  background-color: var(--ion-color-primary);
}

.tertiary-bg {
  background-color: var(--ion-color-tertiary);
}

.recycle-bg {
  background-color: var(--ion-color-recycle);
}

.waste-bg {
  background-color: var(--ion-color-waste);
}

ng-autocomplete {
  margin: 1px;
  .autocomplete-container {
    box-shadow: none;
  }
  input {
    background-image: url("assets/icons/a-z.svg");
    background-repeat: no-repeat;
    background-size: 25px 39px !important;
    background-position: 9px 1px;
    border-radius: 10px;
    padding-left: 40px !important;
  }
  .input-container {
    padding: 0 10px;
  }

  .suggestions-container {
    position: fixed !important;
    left: 0;
  }

  .material-icons {
    margin-right: 10px;
  }
}

.bordered-icon {
  border: 1px solid var(--ion-color-medium);
  border-radius: 5px;
  background-color: white;
  margin-right: 16px;
  margin-left: 16px;
  ion-label {
    opacity: 0.7;
  }
}

.ios .bordered-icon {
  margin-right: 10px;
  margin-left: 20px;
  padding: 2px;
}

/*
 * Fullcalendar Custom CSS
 * ----------------------------------------------------------------------------
 */

:root {
  --fc-today-bg-color: var(--ion-color-light-shade);
}

.fc-event {
  height: 7px !important;
}

.fc-time {
  display: none;
}

.fc-col-header-cell-cushion,
.fc-daygrid-day-number {
  color: var(--ion-color-secondary);
}

.fc-daygrid-day-number {
  color: var(--ion-color-dark);
}

.fc-h-event {
  pointer-events: none !important;
}
