/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
:root {
  --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
  --ck-color-mention-text: hsl(341, 100%, 30%);
  --ck-highlight-marker-blue: hsl(201, 97%, 72%);
  --ck-highlight-marker-green: hsl(120, 93%, 68%);
  --ck-highlight-marker-pink: hsl(345, 96%, 73%);
  --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
  --ck-highlight-pen-green: hsl(112, 100%, 27%);
  --ck-highlight-pen-red: hsl(0, 85%, 49%);
  --ck-image-style-spacing: 5px;
  --ck-todo-list-checkmark-size: 16px;
}

/* ckeditor5-image/theme/image.css */
.ck-content .image {
  display: table;
  clear: both;
  text-align: center;
  margin: 0 auto;
}

/* ckeditor5-image/theme/image.css */
.ck-content .image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-width: 20px;
}

/* ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: #333333;
  background-color: transparent;
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
  min-width: 50px;
}

/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}

/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
  width: 100%;
}

/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
  display: block;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
  float: left;
  margin-right: var(--ck-image-style-spacing);
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}

/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: solid 5px #cccccc;
}

/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir=rtl] blockquote {
  border-left: 0;
  border-right: solid 5px #cccccc;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
  list-style: none;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li {
  margin-bottom: 5px;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li .todo-list {
  margin-top: 5px;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input {
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  width: var(--ck-todo-list-checkmark-size);
  height: var(--ck-todo-list-checkmark-size);
  vertical-align: middle;
  border: 0;
  left: -25px;
  margin-right: -15px;
  right: 0;
  margin-left: 0;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid #333333;
  border-radius: 2px;
  transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::after {
  display: block;
  position: absolute;
  box-sizing: content-box;
  pointer-events: none;
  content: "";
  left: calc(var(--ck-todo-list-checkmark-size) / 3);
  top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
  border-style: solid;
  border-color: transparent;
  border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8) calc(var(--ck-todo-list-checkmark-size) / 8) 0;
  transform: rotate(45deg);
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::before {
  background: #26ab33;
  border-color: #26ab33;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::after {
  border-color: white;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label .todo-list__label__description {
  vertical-align: middle;
}

/* ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
  margin: 15px 0;
  height: 4px;
  background: #dedede;
  border: 0;
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
  background-color: var(--ck-highlight-marker-yellow);
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
  background-color: var(--ck-highlight-marker-green);
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
  background-color: var(--ck-highlight-marker-pink);
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
  background-color: var(--ck-highlight-marker-blue);
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
  color: var(--ck-highlight-pen-red);
  background-color: transparent;
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
  color: var(--ck-highlight-pen-green);
  background-color: transparent;
}

/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
  font-size: 0.7em;
}

/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
  font-size: 0.85em;
}

/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
  font-size: 1.4em;
}

/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
  font-size: 1.8em;
}

/* ckeditor5-basic-styles/theme/code.css */
.ck-content code {
  background-color: rgba(199, 199, 199, 0.3);
  padding: 0.15em;
  border-radius: 2px;
}

/* ckeditor5-table/theme/table.css */
.ck-content .table {
  margin: 1em auto;
  display: table;
}

/* ckeditor5-table/theme/table.css */
.ck-content .table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  border: 1px double #b3b3b3;
}

/* ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
  min-width: 2em;
  padding: 0.4em;
  border: 1px solid #bfbfbf;
}

/* ckeditor5-table/theme/table.css */
.ck-content .table table th {
  font-weight: bold;
  background: rgba(0, 0, 0, 0.05);
}

/* ckeditor5-table/theme/table.css */
.ck-content[dir=rtl] .table th {
  text-align: right;
}

/* ckeditor5-table/theme/table.css */
.ck-content[dir=ltr] .table th {
  text-align: left;
}

/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
  position: relative;
  clear: both;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break::after {
  content: "";
  position: absolute;
  border-bottom: 2px dashed #c4c4c4;
  width: 100%;
}

/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label {
  position: relative;
  z-index: 1;
  padding: 0.3em 0.6em;
  display: block;
  text-transform: uppercase;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
  font-size: 0.75em;
  font-weight: bold;
  color: #333333;
  background: white;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.15);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
  clear: both;
  margin: 1em 0;
  display: block;
  min-width: 15em;
}

/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
  padding: 1em;
  color: #353535;
  background: rgba(199, 199, 199, 0.3);
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  text-align: left;
  direction: ltr;
  tab-size: 4;
  white-space: pre-wrap;
  font-style: normal;
  min-width: 200px;
}

/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code {
  background: unset;
  padding: 0;
  border-radius: 0;
}

/* ckeditor5-mention/theme/mention.css */
.ck-content .mention {
  background: var(--ck-color-mention-background);
  color: var(--ck-color-mention-text);
}

@media print {
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break {
    padding: 0;
  }

  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break::after {
    display: none;
  }
}
.waste {
  color: var(--ion-color-waste);
}

.recycle {
  color: var(--ion-color-recycle);
}

.organic {
  color: var(--ion-color-organic);
}

.paper {
  color: var(--ion-color-paper);
}

.special {
  color: var(--ion-color-special);
}

.food {
  color: var(--ion-color-food);
}

.clean_up {
  color: var(--ion-color-clean_up);
}

.hard_waste {
  color: var(--ion-color-hard_waste);
}

.greenwaste {
  color: var(--ion-color-greenwaste);
}

.colour-dot {
  background: currentColor;
  border: 1px solid black;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 7px !important;
}

.colour-dot {
  border: 1px solid black;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
}

figure.attachment {
  margin: 0px;
  margin-right: 10px;
  display: inline-block;
}

figcaption.caption {
  display: none;
}

figure.attachment.pdf > figcaption.caption {
  display: inline-block;
}
figure.attachment.pdf > figcaption.caption .size {
  display: none;
}

figcaption.caption {
  display: none;
}

.bin-icon {
  font-size: 2.5em;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.logo {
  max-width: 85px;
  max-height: 40px;
  padding: 0px;
  margin: 0px;
}

ion-col.menu-col {
  text-align: center;
  border-bottom: 1px solid var(--ion-color-light-shade);
  border-right: 1px solid var(--ion-color-light-shade);
}

ion-col.menu-col:last-child {
  border-right: 1px solid var(--ion-color-light-shade);
}

google-map {
  min-width: 200px;
  min-height: 200px;
}

.container-grid {
  height: 100%;
}

.container-row {
  height: 100%;
}

.container-col {
  min-height: 50%;
}

.widget-btn:hover {
  background-color: var(--ion-color-light-tint);
  color: white;
}

[data-trix-content-type="image/png"] {
  pointer-events: none;
}

.ck-content a {
  color: var(--ion-color-secondary);
  text-decoration: underline;
  cursor: pointer;
}

.secondary-bg {
  background-color: var(--ion-color-primary);
}

.tertiary-bg {
  background-color: var(--ion-color-tertiary);
}

.recycle-bg {
  background-color: var(--ion-color-recycle);
}

.waste-bg {
  background-color: var(--ion-color-waste);
}

ng-autocomplete {
  margin: 1px;
}
ng-autocomplete .autocomplete-container {
  box-shadow: none;
}
ng-autocomplete input {
  background-image: url("assets/icons/a-z.svg");
  background-repeat: no-repeat;
  background-size: 25px 39px !important;
  background-position: 9px 1px;
  border-radius: 10px;
  padding-left: 40px !important;
}
ng-autocomplete .input-container {
  padding: 0 10px;
}
ng-autocomplete .suggestions-container {
  position: fixed !important;
  left: 0;
}
ng-autocomplete .material-icons {
  margin-right: 10px;
}

.bordered-icon {
  border: 1px solid var(--ion-color-medium);
  border-radius: 5px;
  background-color: white;
  margin-right: 16px;
  margin-left: 16px;
}
.bordered-icon ion-label {
  opacity: 0.7;
}

.ios .bordered-icon {
  margin-right: 10px;
  margin-left: 20px;
  padding: 2px;
}

/*
 * Fullcalendar Custom CSS
 * ----------------------------------------------------------------------------
 */
:root {
  --fc-today-bg-color: var(--ion-color-light-shade);
}

.fc-event {
  height: 7px !important;
}

.fc-time {
  display: none;
}

.fc-col-header-cell-cushion,
.fc-daygrid-day-number {
  color: var(--ion-color-secondary);
}

.fc-daygrid-day-number {
  color: var(--ion-color-dark);
}

.fc-h-event {
  pointer-events: none !important;
}